import * as React from "react"
import Content from "./content"

const Terms = ({ title, img, pageContext: { translate } }) => {
  const definitionsData = [
    {
      title: translate.t?.additional_services,
      description: translate.t?.tnc_phrase_9,
    },
    {
      title: translate.t?.agreement,
      description: translate.t?.tnc_phrase_10,
    },
    {
      title: translate.t?.code_of_conduct,
      description: translate.t?.tnc_phrase_11,
    },
    {
      title: translate.t?.company,
      description: translate.t?.tnc_phrase_12,
    },
    {
      title: translate.t?.dispute_procedure,
      description: translate.t?.tnc_phrase_13,
    },
    {
      title: translate.t?.general_terms_and_conditions,
      description: translate.t?.tnc_phrase_14,
    },
    {
      title: translate.t?.facilitator,
      description: translate.t?.tnc_phrase_15,
    },
    {
      title: translate.t?.marketplace,
      description: translate.t?.tnc_phrase_16,
    },
    {
      title: translate.t?.producer,
      description: translate.t?.tnc_phrase_17,
    },
    {
      title: translate.t?.services,
      description: translate.t?.tnc_phrase_18,
    },
    {
      title: translate.t?.user,
      description: translate.t?.tnc_phrase_19,
    },
  ]
  return (
    <>
      {
        <div className="text_page">
          <div className="content">
            <div className="text-img">
              <img src={img} alt="Terms and Conditions" />
              <h1 className="heading-h1 semi">{title}</h1>
            </div>
            <div className="all-content">
              <Content heading={translate.t?.tnc_phrase_1}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_2}
                </p>

                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_3}
                </p>
              </Content>

              <Content heading={translate.t?.tnc_phrase_4}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_5}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_6}
                </p>
              </Content>

              <Content heading={translate.t?.tnc_phrase_7}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_8}
                </p>
                <table className="terms_definitions_table">
                  {definitionsData.map((item, index) => (
                    <tr key="index">
                      <td className="text-16 text-medium bold">{item.title}</td>
                      <td className="text-16 text-medium">
                        {item.description}
                      </td>
                    </tr>
                  ))}
                </table>
              </Content>

              <Content heading={translate.t?.tnc_phrase_20}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_21}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_22}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_23}
                </p>
              </Content>

              <Content heading={translate.t?.tnc_phrase_24}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_25}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_26}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_27}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_28}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_29}
                </p>
              </Content>
              <Content heading={translate.t?.tnc_phrase_30}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_31}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_32}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_33}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_34}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_35}
                </p>
              </Content>
              <Content heading={translate.t?.tnc_phrase_36}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_37}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_38}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_39}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_40}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_41}
                </p>
              </Content>
              <Content heading={translate.t?.tnc_phrase_42}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_43}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_44}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_45}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_46}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_47}
                </p>
              </Content>
              <Content heading={translate.t?.tnc_phrase_48}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_49}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_50}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_51}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_52}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_53}
                </p>
              </Content>
              <Content heading={translate.t?.tnc_phrase_54}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_55}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_56}{" "}
                </p>
              </Content>
              <Content heading={translate.t?.tnc_phrase_57}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_58}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_59}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_60}
                </p>
              </Content>
              <Content heading={translate.t?.tnc_phrase_61}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_62}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_63}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_64}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_65}
                </p>
              </Content>
              <Content heading={translate.t?.tnc_phrase_66}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_67}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_68}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_69}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_70}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_71}{" "}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_72}{" "}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_73}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_74}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_75}{" "}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_76}{" "}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_77}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_78}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_79}{" "}
                </p>
              </Content>
              <Content heading={translate.t?.tnc_phrase_80}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_81}
                </p>
              </Content>
              <Content heading={translate.t?.tnc_phrase_82}>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_83}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_84}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_85}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_86}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_87}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_88}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_89}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_90}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_91}{" "}
                  <a href="https://www.arn.se/">
                    Startsida | Allmänna reklamationsnämnden
                  </a>
                  .
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.tnc_phrase_92}
                </p>
              </Content>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Terms

import * as React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import TextPage from "../components/textpage/terms"
import term from "../assets/images/privacy.jpg"
import languages from "../utils/enums/languages"

const Terms = ({ location, pageContext: { translate } }) => (
  <Layout
    secondary
    pageContext={{ translate }}
    location={location}
    SEO={({ data }) => (
      <SEO
        title={data.t?.tnc_eraofwe}
        description={data.t?.tnc_metad}
        keywords={data.t?.keywords_tnc}
        languages={[
          ...languages.map(l => {
            return {
              hrefLang: l || 'en',
              href: `${process.env.GATSBY_SITE_URL}${
                l ? "/" + l + "/" : "/"
              }terms-and-condition/`,
            }
          }),
          {
            hrefLang: "x-default",
            href: `${process.env.GATSBY_SITE_URL}/terms-and-condition/`,
          },
        ]}
      />
    )}
  >
    <>
      {
        <>
          <TextPage pageContext={{ translate }} title={translate.t?.tnc_eraofwe} img={term} />
        </>
      }
    </>
  </Layout>
)

export default Terms

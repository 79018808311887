import * as React from "react";

const Content = ({heading, children}) => {
  
  return (
  <>
    {
      (

    <div className="detail">
      {heading && <h2 className="heading-h2 text-darker semi">
         {heading}
       </h2>
      }
      {children}
    </div>
  )
    }
    </>
  )
}

export default Content
